export const ADMIN_RIGHTS = {
    SUPER: 'RW+',
    ALL: 'RW',
    READ: 'R',
    NONE: 'N'
}

export const IDPROOF_TYPES = [
    { name: "PAN", value: "PAN" },
    { name: "Aadhaar", value: "AADHAAR" },
    { name: "Driving License", value: "DL" },
]

export const TXN_STATUSES = [
    { label: 'Approved', value: 'APPROVED' },
    { label: 'Pending', value: 'PENDING' },
    { label: 'Rejected', value: 'REJECTED' },
]

export const SCHEME_STATUSES = [
    { label: 'Active', value: 'ACTIVE' },
    { label: 'Inactive', value: 'INACTIVE' },
    { label: 'Deleted', value: 'DELETED' },
]

export const REWARD_STATUSES = [
    { label: 'Pending', value: 'PENDING' },
    { label: 'Claimed', value: 'CLAIMED' },
    { label: 'Processed', value: 'PROCESSED' },
    { label: 'Completed', value: 'COMPLETED' },
]

export const ROLES = [
    { name: "Super Admin", code: "SUPER_ADMIN" },
    { name: "Admin", code: "ADMIN" },
    { name: "Executive", code: "EXECUTIVE" },
    { name: "TSI", code: "TSI" },
]

export const FLOORS = ["G", "G+1", "G+2", "G+3", "G+4", "G+5", "Other"]

export const ROLE_CODES = {
    SUPER_ADMIN: "SUPER_ADMIN",
    ADMIN: "ADMIN",
    EXECUTIVE: "EXECUTIVE",
    TSI: "TSI",
}

export const MOVEMENT_TYPES = [
    { label: 'Inward', value: 'INWARD' },
    { label: 'Outward', value: 'OUTWARD' },
]

export const format = "YYYY-MM-DD HH:mm:ss";
export const format_only_date = "DD-MM-YYYY";
export const format_export = "DD-MM-YYYY HH:mm:ss";
export const format_fancy = "Do MMM YYYY HH:mm:ss";
export const format_display_withtime = "Do MMM YYYY HH:mm:ss a";
export const format_without_time = "YYYY-MM-DD";
export const format_display = "DD/MM/YYYY";
// export const format_export = "DD/MM/YY";
export const format_display_date = "Do MMM YYYY HH:mm";
export const format_display_fancy_notime = "Do MMM YYYY";
export const format_display_fancy = "Do MMM YYYY hh:mm a";
export const format_display_fancy_my = "MMM YYYY";
export const format_display_fancy_notime_withDay = "dddd, Do MMM";
export const format_display_fancy_notime_withDayAndyear = "dddd, Do MMM YYYY";

export const MAP_API_KEY = "37badffe4161257e"