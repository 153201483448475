import { ROLE_CODES } from "Common/Constants";
import { URLS } from "Common/urls";
import RestService from "Services/RestService";

const { createContext, useState, useContext, useEffect } = require("react");

const AUTH_USER = "auth_user"
const AUTH_TOKEN = "auth_token"

const getUser = () => localStorage.getItem(AUTH_USER)
const saveUser = user => localStorage.setItem(AUTH_USER, user)
const removeUser = () => localStorage.removeItem(AUTH_USER)

const saveToken = token => localStorage.setItem(AUTH_TOKEN, token)
const getToken = () => localStorage.getItem(AUTH_TOKEN)

const SysBrewAuthContext = createContext()

const SysBrewAuthProvider = ({ children }) => {
    const [user, setUser] = useState({})
    const [isLoading, setLoading] = useState(false)
    const [isAuthenticated, setAuthenticated] = useState(false)
    const [token, setToken] = useState(undefined)
    const [error, setError] = useState(false)
    const [errorDescription, setErrorDescription] = useState(undefined)

    useEffect(() => {
        if (getToken()) {
            setLoading(true)
            RestService.post(URLS.AUTHENTICATE_TOKEN)
                .then(({ statusCode, data }) => {
                    if (statusCode === 200) {
                        setToken(data['sysbrew-x-token'])
                        saveToken(data['sysbrew-x-token'])
                        setAuthenticated(true)
                        const user = JSON.parse(getUser() || "{}");
                        user.start_date = data.start_date
                        user.end_date = data.end_date
                        user.status = data.status
                        saveUser(JSON.stringify(user))
                        if (user.role_id === ROLE_CODES.TSI) {
                            setAuthenticated(false)
                            setError(true)
                        }
                        setUser(user)
                        setError(false)
                    } else {
                        setAuthenticated(false)
                        setError(true)
                    }
                })
                .catch((error) => {
                    setAuthenticated(false)
                    setError(true)
                })
                .finally(() => {
                    setLoading(false)
                })
        }
    }, [])

    const logout = ({ returnTo = undefined }) => {
        removeUser()
        setToken(undefined)
        setUser({})
        setAuthenticated(false)
    }

    const loginWithRedirect = (mobile, email, password, redirectTo) => {
        setLoading(true)
        RestService.post(URLS.LOGIN, { mobile, email, password })
            .then(({ statusCode, data }) => {
                if (statusCode === 200) {
                    setToken(data['sysbrew-x-token'])
                    saveToken(data['sysbrew-x-token'])
                    setUser(data)
                    delete data['sysbrew-x-token']
                    saveUser(JSON.stringify(data))
                    if (user.role_id === ROLE_CODES.TSI) {
                        setAuthenticated(false)
                        setError(true)
                        setErrorDescription("You don't have access to the console. Please reach out to admin to give you rights")
                    } else {
                        setAuthenticated(true)
                        setError(false)
                        setErrorDescription(undefined)
                    }
                } else {
                    setAuthenticated(false)
                    setError(true)
                }
            })
            .catch((error) => {
                console.log(error)
                setErrorDescription(error && error.error && error.error.description)
                setAuthenticated(false)
                setError(true)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    return (
        <SysBrewAuthContext.Provider value={{
            user,
            setUser,
            isLoading,
            setLoading,
            isAuthenticated,
            setAuthenticated,
            logout,
            token,
            loginWithRedirect,
            error,
            errorDescription
        }}>
            {children}
        </SysBrewAuthContext.Provider>
    )
}

const useSysBrewAuth = () => {
    const { user,
        isLoading,
        isAuthenticated,
        logout,
        token,
        error,
        loginWithRedirect,
        errorDescription
    } = useContext(SysBrewAuthContext)


    return {
        user,
        isLoading,
        isAuthenticated,
        token,
        logout,
        error,
        loginWithRedirect,
        errorDescription
    }
}

export { SysBrewAuthProvider, useSysBrewAuth }